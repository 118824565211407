import React from 'react'
import Search, { SearchOptionCard } from '@octanner/prism-core/Search'
import { useDebouncedValue } from '@octanner/prism-core/hooks'
import { useIdentitySearch } from '../../hooks/useIdentitySearch'
import {
  IdentityBase,
  IdentityWithErrors,
} from '../../models/CelebrationGroupEdit'

const isIdentityBase = (object?: object): object is IdentityBase =>
  object && 'id' in object
const formatIdentity = (identity: IdentityBase) =>
  `${identity.preferredName ?? ''} ${identity.lastName ?? ''}`.trim()

export interface Response {
  identitySearch: {
    identities: IdentityBase[]
  }
}

interface Props {
  'data-testid'?: string
  customerId: string
  onChange: (value?: IdentityBase) => void
  identity?: IdentityWithErrors
}

const IdentitySelector = ({
  'data-testid': dataTestId,
  customerId,
  onChange,
  identity,
}: Props) => {
  const [searchText, setSearchText] = React.useState('')
  const debouncedText = useDebouncedValue(searchText)
  const results = useIdentitySearch(customerId, debouncedText)
  const options = React.useMemo(
    () =>
      results.data?.identitySearch.identities
        ? results.data?.identitySearch.identities.map((i) => ({
            ...i,
            disabled: false,
          }))
        : [],
    [results.data?.identitySearch.identities]
  )

  return (
    <Search<IdentityBase | undefined>
      id="people-search"
      inputDataTestId={dataTestId}
      // @ts-ignore this is a boolean prop
      multiple={false}
      error={Boolean(identity?.errors)}
      helperText={
        identity?.errors?.length ? identity.errors[0] : 'Enter a name to search'
      }
      loading={results.loading}
      // @ts-ignore bad typing from prism
      value={identity ?? ''}
      onChange={(_, selectedOptions) => {
        if (!isIdentityBase(selectedOptions)) return onChange()
        onChange(selectedOptions)
      }}
      onInputChange={(_, searchText) => setSearchText(searchText)}
      options={options}
      placeholder="Admin recipient"
      getOptionLabel={formatIdentity}
      renderOption={(props, option) => (
        <SearchOptionCard
          {...props}
          data-testid={'identity-selector:option:' + option.id}
          title={formatIdentity(option)}
        />
      )}
    />
  )
}

export default IdentitySelector
