import { styled } from '@mui/material/styles'
import { FormControlLabel, Radio, RadioGroup } from '@octanner/prism-core'
import React from 'react'
import Collapse from '../common/components/Collapse'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'

const NestedSection = styled('section')`
  padding-left: 72px;
`
const NestedCollapse = styled(Collapse)`
  padding-left: 72px;
`
const dataTestPrefix = 'yps:yearbooks'

export default function Yearbooks() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  return (
    <>
      <Collapse
        dataTestPrefix={dataTestPrefix}
        id="yearsbooks:switch"
        isOpen={groupSettingsContext?.yearbookEnabled}
        onChange={() => dispatch({ type: 'toggleYearbook' })}
        text="Yearbooks"
      />
      {groupSettingsContext?.yearbookEnabled && (
        <>
          <NestedSection>
            <RadioGroup
              value={
                groupSettingsContext?.printYearbookEnabled
                  ? 'printed'
                  : 'digital'
              }
              onChange={() =>
                dispatch({ type: 'toggleDigitalorPrintYearbook' })
              }
            >
              <FormControlLabel
                value="digital"
                control={<Radio />}
                label="Digital Only"
              />
              <FormControlLabel
                value="printed"
                control={<Radio />}
                label="Print and Digital"
              />
            </RadioGroup>

            {groupSettingsContext?.printYearbookEnabled && (
              <NestedSection>
                <RadioGroup
                  value={groupSettingsContext?.printCoverType}
                  onChange={() => dispatch({ type: 'togglePrintCoverType' })}
                >
                  <FormControlLabel
                    value="HARD_BOUND"
                    control={<Radio />}
                    label="Hardbound Cover"
                  />
                  <FormControlLabel
                    value="SOFT_BOUND"
                    control={<Radio />}
                    label="Softbound Cover"
                  />
                </RadioGroup>
              </NestedSection>
            )}
          </NestedSection>
          <NestedCollapse
            isOpen={groupSettingsContext?.leaderNotesEnabled}
            onChange={() => dispatch({ type: 'toggleLeaderNotes' })}
            text="Leader Personal Notes"
            variant="body1"
            id="yearbooks:leader"
            dataTestPrefix={dataTestPrefix}
            hideIcon
          >
            <NestedSection>
              <Collapse
                isOpen={groupSettingsContext?.leaderPhotoEnabled}
                onChange={() => dispatch({ type: 'toggleLeaderPhoto' })}
                text="Leader Photos"
                variant="body1"
                id="yearbooks:photos"
                hideIcon
                fixedHeight
              />
              <Collapse
                isOpen={groupSettingsContext?.leaderVideoEnabled}
                onChange={() => dispatch({ type: 'toggleLeaderVideo' })}
                text="Leader Video"
                variant="body1"
                id="yearbooks:video"
                hideIcon
                fixedHeight
              />
            </NestedSection>
          </NestedCollapse>
          <NestedCollapse
            isOpen={groupSettingsContext?.peerNotesEnabled}
            onChange={() => dispatch({ type: 'togglePeerNotes' })}
            text="Peer Personal Notes"
            variant="body1"
            id="yearbooks:peer"
            dataTestPrefix={dataTestPrefix}
            hideIcon
          >
            <NestedSection>
              <Collapse
                isOpen={groupSettingsContext?.peerPhotoEnabled}
                onChange={() => dispatch({ type: 'togglePeerPhoto' })}
                text="Peer Photos"
                variant="body1"
                id="peer:photos"
                hideIcon
                fixedHeight
              />
              <Collapse
                isOpen={groupSettingsContext?.peerVideoEnabled}
                onChange={() => dispatch({ type: 'togglePeerVideo' })}
                text="Peer Video"
                variant="body1"
                id="peer:video"
                hideIcon
                fixedHeight
              />
              <Collapse
                isOpen={groupSettingsContext?.autoInviteEnabled}
                onChange={() => dispatch({ type: 'toggleAutoInvites' })}
                text="Auto-Invite"
                variant="body1"
                id="peer:auto-invite"
                hideIcon
                fixedHeight
              />
            </NestedSection>
          </NestedCollapse>
        </>
      )}
    </>
  )
}
