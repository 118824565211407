import { Button, Typography } from '@octanner/prism-core'
import { DateTime } from 'luxon'
import React from 'react'
import {
  ContentContainer,
  HeaderContainer,
  Label,
} from '../common/components/Styled'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'

interface Props {
  program?: ProgramRepresentation
  onEditClick: () => void
  hideEdit?: boolean
}

const getCelebrationFrequencyText = (
  program?: ProgramRepresentation
): string => {
  switch (program?.celebrationFrequency) {
    default:
    case 'DAILY':
      return 'Day of'
    case 'DAILY_BATCH_MONTHLY':
      return 'Day of - Batched Monthly'
    case 'MONTHLY':
      return 'Monthly'
    case 'ANNUALLY':
      return 'Annualy'
  }
}

export default function GeneralProgramSettings({
  program,
  onEditClick,
  hideEdit,
}: Props): JSX.Element {
  return (
    <section data-testid="mainSettings:programSettings:container">
      <HeaderContainer>
        <Typography variant="h3" data-testid="program:view:title">
          Program Settings
        </Typography>
        {!hideEdit && (
          <Button
            data-testid="program:view:edit"
            variant="text"
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </HeaderContainer>
      <ContentContainer>
        <Label>Recognition Program ID</Label>
        <Typography>{program?.victoriesProgram?.id}</Typography>
        <Label data-testid="mainSettings:programSettings:catchUpDate:label">
          Catch-Up Date
        </Label>
        <Typography data-testid="mainSettings:programSettings:catchUpDate:value">
          {program?.catchupTimestamp &&
            DateTime.fromISO(program.catchupTimestamp).toLocaleString(
              DateTime.DATE_MED
            )}
        </Typography>
        <Label data-testid="mainSettings:programSettings:celebrationFrequency:label">
          Celebration Frequency
        </Label>
        <Typography data-testid="mainSettings:programSettings:celebrationFrequency:value">
          {getCelebrationFrequencyText(program)}
        </Typography>
        <Label data-testid="mainSettings:programSettings:yearbookLanguages:label">
          Yearbook Language(s)
        </Label>
        <Typography data-testid="mainSettings:programSettings:yearbookLanguages:value">
          {program?.yearbookLocales?.join(', ')}
        </Typography>
      </ContentContainer>
    </section>
  )
}
