import { styled } from '@mui/material/styles'
import React, { useMemo } from 'react'
import Collapse from '../common/components/Collapse'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'

const NestedSection = styled('section')`
  padding-left: 72px;
`

export default function AnniversaryCertificate() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()
  const expanded = useMemo(
    () =>
      groupSettingsContext?.celebrantCertificateNotificationEnabled ||
      groupSettingsContext?.managerCertificateNotificationEnabled,
    [
      groupSettingsContext?.celebrantCertificateNotificationEnabled,
      groupSettingsContext?.managerCertificateNotificationEnabled,
    ]
  )

  return (
    <>
      <Collapse
        id="anniversaryCertificate:switch"
        isOpen={expanded}
        onChange={() => dispatch({ type: 'toggleAnniversaryCertificate' })}
        text="Anniversary Certificate"
      />
      {expanded && (
        <NestedSection>
          <Collapse
            isOpen={groupSettingsContext?.managerCertificateNotificationEnabled}
            onChange={() => dispatch({ type: 'toggleManagerCertificate' })}
            text="Email to the Manager"
            variant="body1"
            id="managerCertificate:switch"
            hideIcon
            fixedHeight
          />
          <Collapse
            isOpen={
              groupSettingsContext?.celebrantCertificateNotificationEnabled
            }
            onChange={() => dispatch({ type: 'toggleCelebrantCertificate' })}
            text="Email to the Celebrant"
            variant="body1"
            id="celebrantCertificate:switch"
            hideIcon
            fixedHeight
          />
        </NestedSection>
      )}
    </>
  )
}
