import { styled } from '@mui/material/styles'
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@octanner/prism-core'
import React from 'react'
import colors from '../common/colors'
import { Fieldset, Label } from '../common/components/Styled'
import { FormValue } from '../models/SchedulingForm'

const GridFieldset = styled(Fieldset)`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: 2fr 3fr;
`
const StyledSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.spacing(3)};
  height: 56;
`
const ErrorText = styled('span')`
  display: block;
  font-size: 12px;
`
const ErrorContainer = styled('div')`
  color: ${colors.red};
  margin-top: -20px;
  margin-left: ${({ theme }) => theme.spacing(5)};
  grid-column: span 2;
`

export type ChangeEvent = { id: string } & (
  | {
      type: 'text'
      value: string
    }
  | {
      type: 'hand_in'
      dayOfMonth: string
      month: string
    }
)

interface FieldProps {
  model: FormValue
  onChange: (event: ChangeEvent) => void
  submitted: boolean
}

export default function FormField({
  model,
  onChange,
  submitted,
}: FieldProps): JSX.Element {
  if (model.type === 'text') {
    return (
      <TextField
        style={{ gridColumnStart: model.pos[0], gridRowStart: model.pos[1] }}
        id={model.id}
        label={model.label}
        error={submitted && Boolean(model.errors?.length)}
        helperText={
          submitted &&
          model.errors?.map((error) => (
            <ErrorText key={error}>{error}</ErrorText>
          ))
        }
        value={model.value}
        onChange={(e) =>
          onChange({ id: model.id, type: 'text', value: e.target.value })
        }
        disabled={model.disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Label>days {model.isPositive ? 'after' : 'before'}</Label>
            </InputAdornment>
          ),
        }}
        data-testid={`scheduling:edit:${model.id}:control`}
        inputProps={{ 'data-testid': `scheduling:edit:${model.id}:input` }}
      />
    )
  }

  return (
    <GridFieldset
      style={{ gridColumnStart: model.pos[0], gridRowStart: model.pos[1] }}
    >
      <TextField
        id={model.id}
        label="In-Hand Date"
        error={submitted && Boolean(model.dayOfMonth.errors?.length)}
        value={model.dayOfMonth.value}
        disabled={model.disabled}
        onChange={(e) =>
          onChange({
            id: model.id,
            type: 'hand_in',
            dayOfMonth: e.target.value,
            month: model.month,
          })
        }
        data-testid={`scheduling:edit:${model.id}:day-of-month:control`}
        inputProps={{
          'data-testid': `scheduling:edit:${model.id}:day-of-month:input`,
        }}
      />
      <StyledSelect
        value={model.month}
        disabled={model.disabled}
        onChange={(e) =>
          onChange({
            id: model.id,
            type: 'hand_in',
            dayOfMonth: model.dayOfMonth.value,
            month: e.target.value as string,
          })
        }
        label="In-Hand Month"
        data-testid={`scheduling:edit:${model.id}:month:control`}
        inputProps={{
          'data-testid': `scheduling:edit:${model.id}:month:select`,
        }}
      >
        <MenuItem value="before">of the month before</MenuItem>
        <MenuItem value="same">of the same month</MenuItem>
        <MenuItem value="after">of the month after</MenuItem>
      </StyledSelect>
      {submitted && model.dayOfMonth.errors?.length && (
        <ErrorContainer>
          {model.dayOfMonth.errors?.map((error) => (
            <ErrorText key={error}>{error}</ErrorText>
          ))}
        </ErrorContainer>
      )}
    </GridFieldset>
  )
}
