import { CelebrationLevelGroupConfig } from '../common/models/CelebrationLevelGroupConfig'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import { YbCelebrationDateOffsets } from '../common/models/YbCelebrationDateOffsets'
import { YbInHandDateOffsets } from '../common/models/YbInHandDateOffsets'
import { TextFormValue } from './CelebrationGroupEdit'

export type FormValue = InputFormValue | HandInFormValue
type Validator = (control: FormValue, state: State) => string | undefined

type ControlBase = {
  pos: [number, number]
  validators: Validator[]
  disabled?: boolean
}

type InputFormValue = {
  id: InputId
  type: 'text'
  label: string
  isPositive: boolean
} & ControlBase &
  TextFormValue

type HandInFormValue = {
  id: 'handIn'
  type: 'hand-in'
  dayOfMonth: TextFormValue
  month: string
} & ControlBase

export type ControlFactory = (
  program?: ProgramRepresentation,
  celebrationDateOffsets?: YbCelebrationDateOffsets,
  inHandOffsets?: YbInHandDateOffsets
) => InputFormValue | HandInFormValue

export type State = {
  [key in InputId]: InputFormValue
} & {
  handIn: HandInFormValue
}

const stateReducer = (acc: object, cur: FormValue) => ({
  ...acc,
  [cur.id]: cur,
})

const factoryBuilder = (factory: ControlFactory) => factory

export const initializeState = (
  program?: ProgramRepresentation,
  celebrationDateOffsets?: YbCelebrationDateOffsets,
  inHandOffsets?: YbInHandDateOffsets
): State =>
  factories
    .map((factory) => factory(program, celebrationDateOffsets, inHandOffsets))
    .reduce(stateReducer, {}) as State

export const validateState = (state: State): State =>
  Object.keys(state)
    .map((key) => state[key] as FormValue)
    .map((control) => {
      if (control.disabled) {
        return control
      }
      if (control.type === 'text') {
        const textErrors = control.validators
          .map((validator) => validator(control, state))
          .filter(Boolean)
        const updatedControl: InputFormValue = {
          ...control,
          type: 'text',
          errors: textErrors.length ? textErrors : undefined,
        }
        return updatedControl
      }

      const handInErrors = control.validators
        .map((validator) => validator(control, state))
        .filter(Boolean)
      const updatedControl: HandInFormValue = {
        ...control,
        type: 'hand-in',
        dayOfMonth: {
          ...control.dayOfMonth,
          errors: handInErrors.length ? handInErrors : undefined,
        },
      }
      return updatedControl
    })
    .reduce(stateReducer, {}) as State

export const hasErrors = (state: State): boolean =>
  Object.keys(state)
    .map((key) => state[key] as FormValue)
    .some((control) =>
      control.type === 'text'
        ? control.errors?.length
        : control.dayOfMonth.errors?.length
    )

export const getControlValue = (state: State, key: InputId): number => {
  const control = state[key]
  if (control.type === 'text' && !control.isPositive) {
    return -1 * getValue(state[key])
  }

  return getValue(state[key])
}

export type InputId =
  | 'creationWindow'
  | 'updateProfile'
  | 'prepEmail'
  | 'managerEmail'
  | 'pointsDeposit'
  | 'peersEmail'
  | 'emptyEmail'
  | 'celebrationDate'
  | 'autoshipTrigger'
  | 'survey'
  | 'handDate'
  | 'fallbackShipping'
  | 'endWindow'
  | 'handIn'

type Comparator = '>' | '>=' | '<=' | '<'

const differenceValidator =
  (id: InputId, comparator: Comparator, error: string): Validator =>
  (control, state) => {
    const value = getValue(control),
      compareToControl = state[id],
      compareToValue = parseInt(compareToControl.value)

    if (compareToControl.disabled) {
      return
    }

    switch (comparator) {
      case '>':
        return value > compareToValue ? undefined : error
      case '>=':
        return value >= compareToValue ? undefined : error
      case '<=':
        return value <= compareToValue ? undefined : error
      case '<':
        return value < compareToValue ? undefined : error
    }
  }

const getValue = (control: FormValue): number =>
  parseInt(control.type === 'text' ? control.value : control.dayOfMonth.value)

const positiveValueValidator = (control: FormValue) =>
  getValue(control) > 0 ? undefined : 'Must be greater than 0'

const requiredValidator = (control: FormValue) =>
  !control.disabled && isNaN(getValue(control)) ? 'Required' : undefined

const configFilter = (
  program: ProgramRepresentation | undefined,
  someFunc: (config: CelebrationLevelGroupConfig) => boolean
) => program?.celebrationLevelGroupConfigs.some(someFunc)

const getInitialValue = (value?: number) =>
  value ? Math.abs(value).toString() : ''

const factories: ControlFactory[] = [
  factoryBuilder((_, celebOffsets) => ({
    id: 'creationWindow',
    type: 'text',
    label: 'Celebration Window Creation',
    isPositive: false,
    pos: [1, 2],
    value: getInitialValue(celebOffsets?.celebrationWindowStart),
    validators: [requiredValidator, positiveValueValidator],
  })),
  factoryBuilder((program, _, inHandOffsets) => ({
    id: 'updateProfile',
    type: 'text',
    label: 'Initial Email to Celebrant',
    isPositive: false,
    pos: [2, 2],
    value: getInitialValue(inHandOffsets.updateProfile),
    disabled: !configFilter(
      program,
      (c) => c.peerNotesEnabled || c.leaderNotesEnabled
    ),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'creationWindow',
        '<',
        'Number must be less than the Celebration Window Creation'
      ),
    ],
  })),
  factoryBuilder((program, celebOffsets) => ({
    id: 'prepEmail',
    type: 'text',
    label: 'Presentation Preparation Email',
    isPositive: false,
    pos: [1, 3],
    value: getInitialValue(celebOffsets?.presentationPreparationEmail),
    disabled: !configFilter(program, (c) => c.presentationReminderEmail),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'creationWindow',
        '<',
        'Number must be less than the Celebration Window Creation'
      ),
    ],
  })),
  factoryBuilder((program, _, inHandOffsets) => ({
    id: 'managerEmail',
    type: 'text',
    label: 'Email to Manager',
    isPositive: false,
    pos: [2, 3],
    value: getInitialValue(inHandOffsets?.managerNotification),
    disabled: !configFilter(program, (c) => c.leaderNotesEnabled),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'updateProfile',
        '<',
        'Number must be less than the Initial Email to Celebrant'
      ),
    ],
  })),
  factoryBuilder((program, celebOffsets) => ({
    id: 'pointsDeposit',
    type: 'text',
    label: 'Points Deposit',
    isPositive: false,
    pos: [1, 4],
    value: getInitialValue(celebOffsets?.pointsDeposit),
    disabled: !configFilter(program, (c) => c.userSelectedOrder),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'creationWindow',
        '<',
        'Number must be less than the Celebration Window Creation'
      ),
    ],
  })),
  factoryBuilder((program, _, inHandOffsets) => ({
    id: 'peersEmail',
    type: 'text',
    label: 'Email to Peers',
    isPositive: false,
    pos: [2, 4],
    value: getInitialValue(inHandOffsets?.peerNotification),
    disabled: !configFilter(program, (c) => c.peerNotesEnabled),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'updateProfile',
        '<',
        'Number must be less than the Initial Email to Celebrant'
      ),
    ],
  })),
  factoryBuilder((_, celebOffsets) => ({
    id: 'handIn',
    type: 'text',
    isPositive: false,
    pos: [1, 5],
    value: getInitialValue(celebOffsets?.inHandTszOffset),
    label: 'In-Hand Date',
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'creationWindow',
        '<',
        'Number must be less than the Celebration Window Creation'
      ),
    ],
  })),
  factoryBuilder((program, _, inHandOffsets) => ({
    id: 'emptyEmail',
    type: 'text',
    label: 'Empty Yearbook Email',
    isPositive: false,
    pos: [2, 5],
    value: getInitialValue(inHandOffsets?.emptyYearbookEmail),
    disabled: !configFilter(program, (c) => c.emptyYearbookEmailEnabled),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'peersEmail',
        '<',
        'Number must be less than the Email to Peers'
      ),
      differenceValidator(
        'managerEmail',
        '<',
        'Number must be less than the Email to Manager'
      ),
    ],
  })),
  factoryBuilder(() => ({
    id: 'celebrationDate',
    type: 'text',
    label: 'Celebration Date ',
    isPositive: false,
    pos: [1, 6],
    value: '0',
    disabled: true,
    validators: [],
  })),
  factoryBuilder((program, _, inHandOffsets) => ({
    id: 'autoshipTrigger',
    type: 'text',
    label: 'Autoship Order Trigger',
    isPositive: false,
    pos: [2, 6],
    value: getInitialValue(inHandOffsets?.autoshipAward),
    disabled: !configFilter(
      program,
      (c) =>
        c.autoshipAwards || c.printYearbookEnabled || c.printCertificateEnabled
    ),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'emptyEmail',
        '<',
        'Number must be less than the Empty Yearbook Email'
      ),
      differenceValidator(
        'creationWindow',
        '<',
        'Number must be less than the Celebration Window Creation'
      ),
      differenceValidator(
        'managerEmail',
        '<',
        'Number must be less than the Email to Manager'
      ),
      differenceValidator(
        'peersEmail',
        '<',
        'Number must be less than the Email to Peers'
      ),
    ],
  })),
  factoryBuilder((program, celebOffsets) => ({
    id: 'survey',
    type: 'text',
    label: 'Survey Email',
    isPositive: true,
    pos: [1, 7],
    value: getInitialValue(celebOffsets?.surveyEmail),
    disabled: !configFilter(program, (c) => c.surveyEnabled),
    validators: [
      requiredValidator,
      positiveValueValidator,
      differenceValidator(
        'endWindow',
        '<',
        'Number must be less than the Celebration Window End'
      ),
    ],
  })),
  factoryBuilder((_, celebOffsets) => ({
    id: 'handDate',
    type: 'text',
    label: 'In-Hand Date',
    isPositive: false,
    pos: [2, 7],
    value: getInitialValue(celebOffsets?.inHandTszOffset),
    disabled: true,
    validators: [],
  })),
  factoryBuilder((_, celebOffsets) => ({
    id: 'fallbackShipping',
    type: 'text',
    label: 'Fallback Award Shipping',
    isPositive: true,
    pos: [1, 8],
    value: getInitialValue(celebOffsets?.fallbackAwardOrder),
    // disabled: !configFilter(program, (c) => c.fallbackOrder),
    disabled: true,
    validators: [
      // positiveValueValidator,
      // requiredValidator,
      // differenceValidator(
      //   'endWindow',
      //   '<',
      //   'Number must be less than the Celebration Window End'
      // ),
    ],
  })),
  factoryBuilder((_, celebOffsets) => ({
    id: 'endWindow',
    type: 'text',
    label: 'Celebration Window End',
    isPositive: true,
    pos: [1, 9],
    value: getInitialValue(celebOffsets?.celebrationWindowEnd),
    validators: [requiredValidator, positiveValueValidator],
  })),
]
