import { useQuery } from '@apollo/client'
import { Response } from '../common/components/IdentitySelector'
import { IDENTITY_SEARCH } from '../graphql'

export const useIdentitySearch = (
  customerId: string,
  debouncedText: string
) => {
  const { data, loading } = useQuery<Response>(IDENTITY_SEARCH, {
    variables: {
      identityCondition: {
        customerId: customerId,
        name: debouncedText,
      },
    },
    skip: debouncedText.trim().length < 1 || !customerId,
  })
  return { data, loading }
}
