import { Actions, ContextData } from '../models/GroupSettingsContext'

export default function SettingsReducer(
  state: ContextData,
  action: Actions
): ContextData {
  switch (action.type) {
    case 'togglePrintCertificates':
      return {
        ...state,
        printCertificateEnabled: !state.printCertificateEnabled,
      }
    case 'selectAnniversaryGift':
      return {
        ...state,
        accrualPointsForServiceEnabled:
          action.accrualPointsForServiceEnabled === undefined
            ? !state.accrualPointsForServiceEnabled
            : action.accrualPointsForServiceEnabled,
        userSelectedOrder:
          action.userSelectedOrder === undefined
            ? !state.userSelectedOrder
            : action.userSelectedOrder,
      }
    case 'toggleYearbook':
      return {
        ...state,
        yearbookEnabled: !state.yearbookEnabled,
        printYearbookEnabled: false,
        leaderNotesEnabled: false,
        leaderPhotoEnabled: false,
        leaderVideoEnabled: false,
        peerNotesEnabled: false,
        peerPhotoEnabled: false,
        peerVideoEnabled: false,
        autoInviteEnabled: false,
        printCoverType: undefined,
      }
    case 'toggleDigitalorPrintYearbook':
      return {
        ...state,
        printYearbookEnabled: !state.printYearbookEnabled,
      }
    case 'togglePrintCoverType':
      return {
        ...state,
        printCoverType:
          state.printCoverType === 'HARD_BOUND' ? 'SOFT_BOUND' : 'HARD_BOUND',
      }
    case 'toggleLeaderNotes':
      return {
        ...state,
        leaderNotesEnabled: !state.leaderNotesEnabled,
        leaderPhotoEnabled: false,
        leaderVideoEnabled: false,
      }
    case 'toggleLeaderPhoto':
      return {
        ...state,
        leaderPhotoEnabled: !state.leaderPhotoEnabled,
      }
    case 'toggleLeaderVideo':
      return {
        ...state,
        leaderVideoEnabled: !state.leaderVideoEnabled,
      }
    case 'togglePeerNotes':
      return {
        ...state,
        peerNotesEnabled: !state.peerNotesEnabled,
        peerPhotoEnabled: false,
        peerVideoEnabled: false,
      }
    case 'togglePeerPhoto':
      return {
        ...state,
        peerPhotoEnabled: !state.peerPhotoEnabled,
      }
    case 'togglePeerVideo':
      return {
        ...state,
        peerVideoEnabled: !state.peerVideoEnabled,
      }
    case 'toggleAutoInvites':
      return {
        ...state,
        autoInviteEnabled: !state.autoInviteEnabled,
      }
    case 'toggleAutoshipAwards':
      return {
        ...state,
        autoshipAwards: !state.autoshipAwards,
      }
    case 'selectBillToLocation':
      return {
        ...state,
        billToLocationType: action.billToLocationType,
      }
    case 'selectShipToAddress':
      return {
        ...state,
        shipToAddressType: action.shipToAddressType,
        shipToAttentionType:
          action.shipToAddressType === 'HOME'
            ? 'CELEBRANT'
            : state.shipToAttentionType,
      }
    case 'selectShipToAttention':
      return {
        ...state,
        shipToAttentionType: action.shipToAttentionType,
      }
    case 'togglePresentationReminderEmail':
      return {
        ...state,
        presentationReminderEmail: !state.presentationReminderEmail,
      }
    case 'toggleCelebrantCongratulatoryNotification':
      return {
        ...state,
        celebrantCongratulatoryNotificationEnabled:
          !state.celebrantCongratulatoryNotificationEnabled,
      }
    case 'togglePeersAnniversaryDayNotification':
      return {
        ...state,
        peersAnniversaryDayNotificationEnabled:
          !state.peersAnniversaryDayNotificationEnabled,
      }
    case 'toggleCelebrantInvitePeersNotification':
      return {
        ...state,
        celebrantInvitePeersNotificationEnabled:
          !state.celebrantInvitePeersNotificationEnabled,
      }
    case 'toggleEmptyYearbookEmail':
      return {
        ...state,
        emptyYearbookEmailEnabled: !state.emptyYearbookEmailEnabled,
      }
    case 'setEmptyYearbookEmailRecipient':
      return {
        ...state,
        emptyYearbookEmailRecipientIdentity: action.identity,
      }
    case 'toggleSurvey':
      return {
        ...state,
        surveyEnabled: !state.surveyEnabled,
      }
    case 'setSurveyUrl':
      return {
        ...state,
        surveyUrl: action.surveyUrl,
      }
    case 'setSurveyUrlErrors':
      return {
        ...state,
        surveyUrlErrors: action.surveyUrlErrors,
      }
    case 'setGroupName':
      return {
        ...state,
        name: action.name,
      }
    case 'toggleMilestone':
      return {
        ...state,
        milestone: !state.milestone,
      }
    case 'setGroupNameErrors':
      return {
        ...state,
        groupNameErrors: action.groupNameErrors,
      }
    case 'setSelectedYears': {
      if (Array.isArray(action.year)) return { ...state, selectedYears: [] }

      const isYearAlreadySelected = state.selectedYears.includes(action.year)
      return {
        ...state,
        selectedYears: isYearAlreadySelected
          ? state.selectedYears.filter((y) => y !== action.year)
          : [...state.selectedYears, action.year],
      }
    }
    case 'toggleManagerAnniversaryDayNotification':
      return {
        ...state,
        managerAnniversaryDayNotificationEnabled:
          !state.managerAnniversaryDayNotificationEnabled,
      }
    case 'toggleManagerCertificate':
      return {
        ...state,
        managerCertificateNotificationEnabled:
          !state.managerCertificateNotificationEnabled,
      }
    case 'toggleCelebrantCertificate':
      return {
        ...state,
        celebrantCertificateNotificationEnabled:
          !state.celebrantCertificateNotificationEnabled,
      }
    case 'toggleAnniversaryCertificate':
      if (
        state.managerCertificateNotificationEnabled ||
        state.celebrantCertificateNotificationEnabled
      ) {
        return {
          ...state,
          managerCertificateNotificationEnabled: false,
          celebrantCertificateNotificationEnabled: false,
        }
      } else {
        return {
          ...state,
          managerCertificateNotificationEnabled: true,
          celebrantCertificateNotificationEnabled: true,
        }
      }
    default: {
      throw Error('Unknown action')
    }
  }
}
