import { styled } from '@mui/material/styles'
import { TextField } from '@octanner/prism-core'
import React from 'react'
import Collapse from '../common/components/Collapse'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'

const NestedSection = styled('section')`
  padding-left: 72px;
`
const UrlInput = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 370px;
`

export default function Survey() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  return (
    <Collapse
      isOpen={groupSettingsContext?.surveyEnabled}
      onChange={() => dispatch({ type: 'toggleSurvey' })}
      text="Survey"
      id="survey:switch"
    >
      <NestedSection>
        <UrlInput
          id="survey-url"
          label="Survey URL"
          error={Boolean(groupSettingsContext?.surveyUrlErrors?.length > 0)}
          helperText={groupSettingsContext?.surveyUrlErrors?.join(', ')}
          value={groupSettingsContext?.surveyUrl}
          onChange={(e) =>
            dispatch({ type: 'setSurveyUrl', surveyUrl: e.target.value })
          }
          autoFocus
          inputProps={{ 'data-testid': 'survey:url' }}
        />
      </NestedSection>
    </Collapse>
  )
}
