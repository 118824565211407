import { styled } from '@mui/material/styles'
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@octanner/prism-core'
import React, { useEffect, useState } from 'react'
import Collapse from '../common/components/Collapse'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'

const NestedContent = styled('div')`
  margin-left: 48px;
`

export default function AnniversaryGift() {
  const dispatch = useGroupSettingsDispatch()
  const groupSettingsContext = useGroupSettings()
  const [checked, setChecked] = useState(
    groupSettingsContext?.userSelectedOrder ||
      groupSettingsContext?.accrualPointsForServiceEnabled
  )
  const [giftType, setGiftType] = useState(
    checked
      ? groupSettingsContext?.userSelectedOrder
        ? 'userGift'
        : 'points'
      : ''
  )

  useEffect(() => {
    setChecked(
      groupSettingsContext?.userSelectedOrder ||
        groupSettingsContext?.accrualPointsForServiceEnabled
    )
  }, [
    groupSettingsContext?.userSelectedOrder,
    groupSettingsContext?.accrualPointsForServiceEnabled,
  ])

  useEffect(() => {
    if (checked) {
      setGiftType(
        groupSettingsContext?.userSelectedOrder ? 'userGift' : 'points'
      )
    } else {
      setGiftType('')
    }
  }, [
    groupSettingsContext?.userSelectedOrder,
    groupSettingsContext?.accrualPointsForServiceEnabled,
    checked,
  ])

  return (
    <>
      <Collapse
        dataTestPrefix="anniversary-gift"
        id="anniversary-gift-switch"
        isOpen={checked}
        onChange={(switchActive) => {
          setChecked(switchActive)
          if (!switchActive) {
            setGiftType('')
            dispatch({
              type: 'selectAnniversaryGift',
              accrualPointsForServiceEnabled: false,
              userSelectedOrder: false,
            })
          }
          if (switchActive) {
            setGiftType('points')
            dispatch({
              type: 'selectAnniversaryGift',
              accrualPointsForServiceEnabled: true,
              userSelectedOrder: false,
            })
          }
        }}
        text="Anniversary Gift"
      />
      {checked && (
        <section>
          <NestedContent>
            <FormGroup>
              <RadioGroup
                name="anniversaryGift"
                value={giftType}
                onChange={(e) =>
                  dispatch({
                    type: 'selectAnniversaryGift',
                    accrualPointsForServiceEnabled: e.target.value === 'points',
                    userSelectedOrder: e.target.value === 'userGift',
                  })
                }
              >
                <FormControlLabel
                  value="points"
                  control={
                    <Radio
                      inputProps={{
                        // @ts-ignore is an actual prop
                        'data-testid': 'anniversary-gift:points',
                      }}
                    />
                  }
                  label="Points"
                  name="points"
                />
                <FormControlLabel
                  value="userGift"
                  control={
                    <Radio
                      inputProps={{
                        // @ts-ignore is an actual prop
                        'data-testid': 'anniversary-gift:user-selected',
                      }}
                    />
                  }
                  label="User Selected Gift"
                  name="userGift"
                />
              </RadioGroup>
            </FormGroup>
          </NestedContent>
        </section>
      )}
    </>
  )
}
