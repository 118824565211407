import { Box, FormControlLabel, FormGroup, Switch } from '@octanner/prism-core'
import React from 'react'
import Collapse from '../common/components/Collapse'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'

const NonMilestoneEmailControls: React.FC = () => {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  return (
    <>
      <Collapse
        isOpen
        text="Anniversary Emails"
        id="anniversary-emails"
        hideIcon
        enableSwitch={false}
      />
      <section>
        <Box data-testid="non-milestone-email-controls">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  data-testid="celebrant-congratulatory-notification"
                  checked={
                    groupSettingsContext?.celebrantCongratulatoryNotificationEnabled
                  }
                  onChange={() =>
                    dispatch({
                      type: 'toggleCelebrantCongratulatoryNotification',
                    })
                  }
                />
              }
              label="Non-Milestone Anniversary Emails - Celebrant"
              sx={{ marginLeft: '50px', gap: '8px' }}
            />
            <FormControlLabel
              control={
                <Switch
                  data-testid="peers-anniversary-day-notification"
                  checked={
                    groupSettingsContext?.peersAnniversaryDayNotificationEnabled
                  }
                  onChange={() =>
                    dispatch({ type: 'togglePeersAnniversaryDayNotification' })
                  }
                />
              }
              label="Non-Milestone Anniversary Emails - Peers"
              sx={{ marginLeft: '50px', gap: '8px' }}
            />
            <FormControlLabel
              control={
                <Switch
                  data-testid="manager-anniversary-day-notification"
                  checked={
                    groupSettingsContext?.managerAnniversaryDayNotificationEnabled
                  }
                  onChange={() =>
                    dispatch({
                      type: 'toggleManagerAnniversaryDayNotification',
                    })
                  }
                />
              }
              label="Non-Milestone Anniversary Emails - Manager"
              sx={{ marginLeft: '50px', gap: '8px' }}
            />
          </FormGroup>
        </Box>
      </section>
    </>
  )
}

export default NonMilestoneEmailControls
