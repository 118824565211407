import { styled } from '@mui/material/styles'
import { Alert, Button } from '@octanner/prism-core'
import colors from '../common/colors'
import { YbProgramOffsets } from '../common/models/ProgramOffsets'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EditSection } from '../common/models/EditSection'
import useCreateDefaultProgram from '../hooks/useCreateDefaultProgram'
import { useGetProgramOffsetsByProgramId } from '../hooks/useGetProgramOffsetsByProgramId'
import { usePatchProgram } from '../hooks/usePatchProgram'
import StylesProvider from '../utilities/StylesProvider'
import GeneralProgramSettings from './GeneralProgramSettings'
import GeneralProgramSettingsEdit, {
  OnSubmitForm,
} from './GeneralProgramSettingsEdit'
import Loading from './Loading'

interface Props {
  programId: string
  onEditGroupClick: (groupId?: string) => void
  onEditAccrualPointRegion: (regionId: string) => void
  onAddRegion: () => void
}

const Root = styled('section')`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(6)};
  > section {
    padding-top: ${({ theme }) => theme.spacing(5)};
    border-top: 1px solid ${colors.gray400};
  }
`

type CommonProps = {
  onCancel: () => void
  hideEdit: boolean
} & Partial<YbProgramOffsets>

const MainSettings = (props: Props) => {
  const [showCreateButton, setShowCreatebutton] = useState<boolean>(false)
  const [showCreationError, setShowCreationError] = useState<boolean>(false)
  const [editSection, setEditSection] = useState<EditSection>()
  const [coreAwardLevelSuccessAlert, setCoreAwardLevelSuccessAlert] =
    useState(false)

  const { data, startPolling, stopPolling } = useGetProgramOffsetsByProgramId(
    props.programId
  )

  const [createDefaultProgram, { error }] = useCreateDefaultProgram(
    props.programId
  )

  const patchProgramConfig = usePatchProgram(props.programId)

  const handleAlertClose = () => setShowCreationError(false)

  const handleCancel = useCallback(
    () => setEditSection(undefined),
    [setEditSection]
  )

  useEffect(() => {
    startPolling(1000)
  }, [startPolling])

  useEffect(() => {
    if (coreAwardLevelSuccessAlert)
      setTimeout(() => {
        setCoreAwardLevelSuccessAlert(false)
      }, 6000)
  }, [coreAwardLevelSuccessAlert])

  useEffect(() => {
    const timer = setTimeout(() => {
      stopPolling()
      if (!data?.program) {
        setShowCreationError(true)
        setShowCreatebutton(true)
      }
    }, 4000)
    return () => clearTimeout(timer)
  }, [stopPolling, data?.program])

  useEffect(() => {
    if (data?.program) stopPolling()
  }, [data?.program, stopPolling])

  const resubmitProgramCreation = () => {
    setShowCreatebutton(false)
    setShowCreationError(false)
    createDefaultProgram()
  }

  const commonProps: CommonProps = useMemo(
    () => ({
      onCancel: handleCancel,
      hideEdit: Boolean(editSection),
      ...data,
    }),
    [handleCancel, editSection, data]
  )

  const handlePatchProgram = async ({
    yearbookLocales,
    ...input
  }: OnSubmitForm) => {
    const existing = commonProps.program?.yearbookLocales ?? []
    const added = yearbookLocales.filter((locale) => !existing.includes(locale))
    const removed = existing.filter(
      (locale) => !yearbookLocales.includes(locale)
    )
    const isNew = Boolean(!commonProps.program)

    await patchProgramConfig(
      {
        programConfigInput: {
          ...input,
          id: props.programId,
          ...(isNew && { programStatus: 'BUILDING' }),
        },
        ...(added.length > 0 && {
          addLocalesInput: {
            programId: props.programId,
            yearbookLocales: added,
          },
        }),
        ...(removed.length > 0 && {
          removeLocalesInput: {
            programId: props.programId,
            yearbookLocales: removed,
          },
        }),
      },
      isNew
    )
    handleCancel()
  }

  if (error) {
    return (
      <Alert severity="error" data-testid="manual-creation-error">
        Something has gone wrong and we are not able to display the program
        settings. Please reach out to support for assistance.
      </Alert>
    )
  }

  if (!commonProps.program && !showCreateButton && !showCreationError) {
    return <Loading />
  }

  return (
    <>
      {showCreationError && (
        <Alert
          severity="error"
          onClose={handleAlertClose}
          data-testid="automatic-creation-error"
        >
          Something has gone wrong and we are not able to display the program
          settings. Please wait for a moment and click the button below to add
          them manually.
        </Alert>
      )}
      {showCreateButton && (
        <Button
          sx={{ width: 192 }}
          onClick={resubmitProgramCreation}
          data-testid="manual-creation-button"
        >
          Add program settings
        </Button>
      )}
      {!showCreateButton && !showCreationError && (
        <StylesProvider>
          <Root data-testid="mainSettingsWrapper">
            {editSection === 'general' ? (
              <GeneralProgramSettingsEdit
                {...commonProps}
                onSubmit={handlePatchProgram}
              />
            ) : (
              <GeneralProgramSettings
                onEditClick={() => setEditSection('general')}
                {...commonProps}
              />
            )}
            <section>
              <Button
                onClick={() =>
                  window.open(
                    'https://jira.octanner.com/secure/CreateIssueDetails!init.jspa?pid=15512&issuetype=3&summary=Program+config+changes+requested+for+CLIENT_NAME&description=h3.+Client+details%0D%0A%2A+Client+name%3A+CLIENT_NAME%0D%0A%2A+Customer+UUID%3A+CLIENT_UUID%0D%0A%2A+Program+UUID%3A+PROGRAM_UUID%0D%0A%0D%0Ah3.+Changes+needed%0D%0A%2A+%0D%0A%0D%0Ah3.+Date+needed+by%0D%0A%2A+&components=18391&customfield_12221=YBC-903&labels=CPTRequest',
                    '_blank'
                  )
                }
                color="secondary"
              >
                Request change
              </Button>
            </section>
            <section></section>
          </Root>
        </StylesProvider>
      )}
    </>
  )
}

export default MainSettings
