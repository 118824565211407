import { styled } from '@mui/material/styles'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from '@octanner/prism-core'
import colors from '../common/colors'
import RadioLabel from '../common/components/RadioLabel'
import React, { useEffect, useState } from 'react'
import Collapse from '../common/components/Collapse'
import {
  BillToLocationType,
  ShipToAddressType,
  ShipToAttentionType,
} from '../common/models/CelebrationLevelGroupConfig'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'
import {
  addressOptions,
  attentionOptions,
  billingLocationOptions,
} from '../models/CelebrationGroupEdit'

const NestedContent = styled('section')`
  margin-left: 48px;
`
const GridContent = styled('section')`
  padding-top: ${({ theme }) => theme.spacing(5)};
  display: flex;
  justify-content: space-around;
`

export default function ShippingInfo() {
  const groupSettingsContext = useGroupSettings()
  const dispatch = useGroupSettingsDispatch()

  const [checked, setChecked] = useState(true)
  const [addressType, setAddressType] = useState(
    groupSettingsContext?.shipToAddressType ?? 'WORK_1'
  )
  const [attentionType, setAttentionType] = useState(
    groupSettingsContext?.shipToAttentionType ?? 'MANAGER'
  )
  const [billToLocationType, setBillToLocationType] = useState(
    groupSettingsContext?.billToLocationType ?? 'BILL_1'
  )

  useEffect(() => {
    setAddressType(groupSettingsContext?.shipToAddressType ?? 'WORK_1')
  }, [groupSettingsContext?.shipToAddressType])

  useEffect(() => {
    setAttentionType(groupSettingsContext?.shipToAttentionType ?? 'MANAGER')
  }, [groupSettingsContext?.shipToAttentionType])

  useEffect(() => {
    setBillToLocationType(groupSettingsContext?.billToLocationType ?? 'BILL_1')
  }, [groupSettingsContext?.billToLocationType])

  return (
    <>
      <Box
        onClick={() => setChecked(!checked)}
        sx={{
          padding: '16px 16px 2px 2px',
          borderTop: `1px solid ${colors.gray400}`,
        }}
        data-testid="shipping-info"
      >
        <Collapse
          id="yearsbooks:shipping-info"
          isOpen={checked}
          text="Shipping Info"
          enableSwitch={false}
        />
      </Box>
      {checked && (
        <section>
          <NestedContent>
            <GridContent>
              <FormGroup>
                <RadioLabel htmlFor="addressType" text="Shipping address:" />
                <RadioGroup
                  id="addressType"
                  value={addressType}
                  onChange={(e) =>
                    dispatch({
                      type: 'selectShipToAddress',
                      shipToAddressType: e.target.value as ShipToAddressType,
                    })
                  }
                >
                  {addressOptions.map((option) => (
                    <FormControlLabel
                      key={option.text}
                      value={option.type}
                      control={<Radio />}
                      label={option.text}
                    />
                  ))}
                </RadioGroup>
              </FormGroup>
              {addressType !== 'HOME' && (
                <FormGroup>
                  <RadioLabel htmlFor="attentionType" text="Attention to:" />
                  <RadioGroup
                    id="attentionType"
                    value={attentionType}
                    onChange={(e) =>
                      dispatch({
                        type: 'selectShipToAttention',
                        shipToAttentionType: e.target
                          .value as ShipToAttentionType,
                      })
                    }
                  >
                    {attentionOptions.map((option) => (
                      <FormControlLabel
                        key={option.text}
                        value={option.type}
                        control={<Radio />}
                        label={option.text}
                      />
                    ))}
                  </RadioGroup>
                </FormGroup>
              )}
              <FormGroup>
                <RadioLabel
                  htmlFor="billingLocationType"
                  text="Billing location configuration:"
                />
                <RadioGroup
                  id="billingLocationType"
                  value={billToLocationType}
                  onChange={(e) =>
                    dispatch({
                      type: 'selectBillToLocation',
                      billToLocationType: e.target.value as BillToLocationType,
                    })
                  }
                >
                  {billingLocationOptions.map((option) => (
                    <FormControlLabel
                      key={option.text}
                      value={option.type}
                      control={<Radio />}
                      label={option.text}
                    />
                  ))}
                </RadioGroup>
              </FormGroup>
            </GridContent>
          </NestedContent>
        </section>
      )}
    </>
  )
}
