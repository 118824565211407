import { useMutation } from '@apollo/client'
import { YbProgramOffsets } from '../common/models/ProgramOffsets'
import { YbProgramOffsetsInput } from '../common/models/YbProgramOffsetsInput'
import { GET_PROGRAM_OFFSETS_BY_PROGRAM_ID, PATCH_OFFSETS } from '../graphql'

export default function usePatchOffsets(programId: string) {
  return useMutation<
    { ybPatchProgramOffsets: YbProgramOffsets },
    { input: YbProgramOffsetsInput }
  >(PATCH_OFFSETS, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
        variables: { programId },
        errorPolicy: 'all',
      },
    ],
  })
}
