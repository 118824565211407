(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material/styles"), require("@octanner/prism-core"), require("react"), require("@apollo/client"), require("luxon"), require("@mui/material/Box"), require("@octanner/prism-core/CircularProgress"), require("@octanner/prism-icons/ArrowRight"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/hooks"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material/styles", "@octanner/prism-core", "react", "@apollo/client", "luxon", "@mui/material/Box", "@octanner/prism-core/CircularProgress", "@octanner/prism-icons/ArrowRight", "@octanner/prism-core/Search", "@octanner/prism-core/hooks"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@mui/material/styles"), require("@octanner/prism-core"), require("react"), require("@apollo/client"), require("luxon"), require("@mui/material/Box"), require("@octanner/prism-core/CircularProgress"), require("@octanner/prism-icons/ArrowRight"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/hooks")) : factory(root["@mui/material/styles"], root["@octanner/prism-core"], root["react"], root["@apollo/client"], root["luxon"], root["@mui/material/Box"], root["@octanner/prism-core/CircularProgress"], root["@octanner/prism-icons/ArrowRight"], root["@octanner/prism-core/Search"], root["@octanner/prism-core/hooks"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__628__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__971__, __WEBPACK_EXTERNAL_MODULE__796__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__683__, __WEBPACK_EXTERNAL_MODULE__553__, __WEBPACK_EXTERNAL_MODULE__409__, __WEBPACK_EXTERNAL_MODULE__463__) => {
return 