import { styled } from '@mui/material/styles'
import { Button, Typography } from '@octanner/prism-core'
import React, { useMemo, useState } from 'react'
import { ActionsContainer, Form, Row } from '../common/components/Styled'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import { YbCelebrationDateOffsets } from '../common/models/YbCelebrationDateOffsets'
import { YbInHandDateOffsets } from '../common/models/YbInHandDateOffsets'
import { YbProgramOffsetsInput } from '../common/models/YbProgramOffsetsInput'
import {
  getControlValue,
  hasErrors,
  initializeState,
  State,
  validateState,
} from '../models/SchedulingForm'
import FormField, { ChangeEvent } from './FormField'

export type OffsetsInput = Omit<YbProgramOffsetsInput, 'programId'>

interface Props {
  program?: ProgramRepresentation
  celebrationDateOffsets?: YbCelebrationDateOffsets
  inHandDateOffsets?: YbInHandDateOffsets
  onCancel: () => void
  onSubmit: (input: OffsetsInput) => void
}

const Header = styled(Typography)`
  font-weight: 700;
`
const FormContent = styled(Row)`
  grid-gap: ${({ theme }) => theme.spacing(6)};
  grid-column-gap: 112px;
  grid-template-columns: repeat(2, 376px);
`
const Actions = styled(ActionsContainer)`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

export default function SchedulingForm({
  onCancel,
  onSubmit,
  program,
  inHandDateOffsets,
  celebrationDateOffsets,
}: Props): JSX.Element {
  const [submitted, setSubmitted] = useState(false)
  const [state, setState] = useState<State>(() =>
    initializeState(program, celebrationDateOffsets, inHandDateOffsets)
  )

  const controls = useMemo(
    () => Object.keys(state).map((key) => state[key]),
    [state]
  )

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitted(true)
    const validatedState = validateState(state)
    const invalid = hasErrors(validatedState)

    if (invalid) {
      setState(validatedState)
      return
    }

    onSubmit({
      inHandDateOffsets: {
        updateProfile: getControlValue(state, 'updateProfile'),
        autoshipAward: getControlValue(state, 'autoshipTrigger'),
        peerNotification: getControlValue(state, 'peersEmail'),
        emptyYearbookEmail: getControlValue(state, 'emptyEmail'),
        managerNotification: getControlValue(state, 'managerEmail'),
      },
      celebrationDateOffsets: {
        surveyEmail: getControlValue(state, 'survey'),
        pointsDeposit: getControlValue(state, 'pointsDeposit'),
        fallbackAwardOrder: getControlValue(state, 'fallbackShipping'),
        celebrationWindowStart: getControlValue(state, 'creationWindow'),
        presentationPreparationEmail: getControlValue(state, 'prepEmail'),
        celebrationWindowEnd: getControlValue(state, 'endWindow'),
        inHandTszOffset: getControlValue(state, 'handIn'),
      },
    })
  }

  const handleChange = (event: ChangeEvent) => {
    if (event.type === 'text') {
      setState((cur) =>
        validateState({
          ...cur,
          [event.id]: {
            ...cur[event.id],
            value: event.value,
          },
        })
      )
      return
    }

    setState((cur) =>
      validateState({
        ...cur,
        [event.id]: {
          ...cur[event.id],
          dayOfMonth: { value: event.dayOfMonth },
          month: event.month,
        },
      })
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <section>
        <Typography variant="h3" data-testid="scheduling:edit:title">
          Scheduling
        </Typography>
      </section>
      <FormContent>
        <div>
          <Header>Celebration Date Timeline</Header>
          <Typography>
            Indicate how many days before or after the Celebration Date each of
            these events should happen.
          </Typography>
        </div>
        <div>
          <Header>In-Hand Date Timeline</Header>
          <Typography>
            Indicate how many days before the In-Hand Date (set in the previous
            column) each of these events should happen.
          </Typography>
        </div>
        {controls.map((model) => (
          <FormField
            key={model.id}
            model={model}
            onChange={handleChange}
            submitted={submitted}
          />
        ))}
      </FormContent>
      <Actions>
        <Button
          data-testid="scheduling:edit:cancel"
          onClick={onCancel}
          color="inherit"
        >
          Cancel
        </Button>
        <Button data-testid="scheduling:edit:save" type="submit">
          Save scheduling
        </Button>
      </Actions>
    </Form>
  )
}
