(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material/styles"), require("@octanner/prism-core"), require("react"), require("@apollo/client"), require("@octanner/prism-icons/Check"), require("@octanner/prism-icons/Close"), require("luxon"), require("@mui/material/Box"), require("@octanner/prism-core/CircularProgress"), require("@mui/system"), require("@octanner/prism-icons/ArrowRight"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/hooks"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material/styles", "@octanner/prism-core", "react", "@apollo/client", "@octanner/prism-icons/Check", "@octanner/prism-icons/Close", "luxon", "@mui/material/Box", "@octanner/prism-core/CircularProgress", "@mui/system", "@octanner/prism-icons/ArrowRight", "@octanner/prism-core/Search", "@octanner/prism-core/hooks"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@mui/material/styles"), require("@octanner/prism-core"), require("react"), require("@apollo/client"), require("@octanner/prism-icons/Check"), require("@octanner/prism-icons/Close"), require("luxon"), require("@mui/material/Box"), require("@octanner/prism-core/CircularProgress"), require("@mui/system"), require("@octanner/prism-icons/ArrowRight"), require("@octanner/prism-core/Search"), require("@octanner/prism-core/hooks")) : factory(root["@mui/material/styles"], root["@octanner/prism-core"], root["react"], root["@apollo/client"], root["@octanner/prism-icons/Check"], root["@octanner/prism-icons/Close"], root["luxon"], root["@mui/material/Box"], root["@octanner/prism-core/CircularProgress"], root["@mui/system"], root["@octanner/prism-icons/ArrowRight"], root["@octanner/prism-core/Search"], root["@octanner/prism-core/hooks"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__655__, __WEBPACK_EXTERNAL_MODULE__202__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__209__, __WEBPACK_EXTERNAL_MODULE__223__, __WEBPACK_EXTERNAL_MODULE__484__, __WEBPACK_EXTERNAL_MODULE__985__, __WEBPACK_EXTERNAL_MODULE__574__, __WEBPACK_EXTERNAL_MODULE__122__, __WEBPACK_EXTERNAL_MODULE__719__, __WEBPACK_EXTERNAL_MODULE__994__, __WEBPACK_EXTERNAL_MODULE__548__, __WEBPACK_EXTERNAL_MODULE__979__) => {
return 