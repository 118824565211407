import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { ThemeProvider } from '@octanner/prism-core'

import React from 'react'
import packageJson from '../../package.json'
import { apolloAuthLink } from '../auth/Auth'

const cache = new InMemoryCache()

const AppProviders: React.FC = ({ children }) => {
  const client = new ApolloClient({
    link: apolloAuthLink.concat(createHttpLink({ uri: '/graphql' })),
    cache,
    name: packageJson.name,
    version: packageJson.version,
  })
  return (
    <ApolloProvider client={client}>
      <ThemeProvider disableInjection>{children}</ThemeProvider>
    </ApolloProvider>
  )
}

export default AppProviders
